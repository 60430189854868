
import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from '../src/Layout/Layout';
import UploadImage from './uploadImage/UploadImage';
import ImageGallery from './deleteImage/ImageGallery';
import CategoryList from './deleteImage/CategoryList';

function App() {
  return (
    <Router>
      <div className='app-container'>
        <Routes>
        <Route path="/" element={<Layout><UploadImage /></Layout>} />
        <Route path="/deleteImage" element={<Layout><CategoryList /></Layout>} />
          <Route path="/deleteImage/:categoryName" element={<Layout> <ImageGallery /> </Layout>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
