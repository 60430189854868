import React, { useState } from "react";
import { TextField, Button, Typography, CircularProgress } from "@mui/material";
import axios from "axios";

const UploadImage = () => {
  const [categoryName, setCategoryName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false); // Track success or failure

  const handleImageChange = (event) => {
    setImageFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage("");

    const formData = new FormData();
    formData.append("categoryName", categoryName);
    formData.append("imageFile", imageFile);

    try {
      // Update the API URL to the correct backend endpoint
      const response = await axios.post(
        "https://gallery-ln9c.onrender.com/api/images/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setMessage(response.data);
      setIsSuccess(true); // Set success state
    } catch (error) {
      if (error.response) {
        // Log more detailed error information
        console.error("Backend returned status code:", error.response.status);
        console.error("Response data:", error.response.data);
        setMessage(`Failed to upload image: ${error.response.data}`);
      } else {
        console.error("Error:", error.message);
        setMessage("Failed to upload image");
      }
      setIsSuccess(false); // Set failure state
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Upload Image
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Category Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          required
        />
        <div style={{ marginTop: "20px" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading || !categoryName || !imageFile}
          >
            {loading ? <CircularProgress size={24} /> : "Upload"}
          </Button>
        </div>
        {message && (
          <Typography
            variant="body1"
            style={{ color: isSuccess ? "green" : "red", marginTop: "20px" }} // Green for success, red for failure
          >
            {message}
          </Typography>
        )}
      </form>
    </div>
  );
};

export default UploadImage;
