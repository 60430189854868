import React from "react";
import { AppBar, Button, IconButton, Grid } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { NavLink, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const handleLocationClick = () => {
    window.open("https://maps.app.goo.gl/LabjHB1DRnuVj7RC6");
  };

  const handlePhoneClick = () => {
    window.open("tel:+91-9591075999");
  };

  const handleEmailClick = () => {
    navigate("/contact-us");
  };

  const handleFacebookClick = () => {
    window.open(
      "https://www.facebook.com/profile.php?id=61554222877205&mibextid=ZbWKwL"
    );
  };

  const handleInstagramClick = () => {
    window.open(
      "https://www.instagram.com/tejomaya_bharatha?igsh=dDliMHkwOHhqZnhx"
    );
  };

  return (
    <div>
      {/* Navbar */}
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#FFA500",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: { xs: "auto", sm: "5%" }, // Responsive height
          padding: { xs: "10px", sm: "0 5%" }, // Adjusted padding for mobile
        }}
      >
        {/* Social Media Icons - Positioned to the right */}
        <Grid
          container
          justifyContent="flex-end"
          sx={{
            position: "relative",
            gap: { xs: "2%", sm: "1%" }, // Increased gap for mobile
            marginRight: { xs: "0", sm: "-90px" }, // Responsive margin right
            marginTop: { xs: "10px", sm: "0px" }, // Adjusted top margin for mobile
            height: "auto",
          }}
        >
          <Grid item>
            <IconButton
              onClick={handleFacebookClick}
              sx={{
                padding: { xs: "6px", sm: "8px" }, // Adjusted padding for mobile
                backgroundColor: "orange",
                borderRadius: "20%",
                top: { xs: "40%", sm: "20%" }, // Shifted by 40% for mobile
              }}
            >
              <FacebookIcon
                sx={{ fontSize: { xs: 25, sm: 30 }, color: "black" }}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleInstagramClick}
              sx={{
                padding: { xs: "6px", sm: "8px" },
                backgroundColor: "orange",
                borderRadius: "20%",
                top: { xs: "40%", sm: "20%" }, // Shifted by 40% for mobile
              }}
            >
              <InstagramIcon
                sx={{ fontSize: { xs: 25, sm: 30 }, color: "black" }}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              sx={{
                padding: { xs: "6px", sm: "8px" },
                backgroundColor: "orange",
                borderRadius: "20%",
                top: { xs: "40%", sm: "20%" }, // Shifted by 40% for mobile
              }}
            >
              <TwitterIcon
                sx={{ fontSize: { xs: 25, sm: 30 }, color: "black" }}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              sx={{
                padding: { xs: "6px", sm: "8px" },
                backgroundColor: "orange",
                borderRadius: "20%",
                top: { xs: "40%", sm: "20%" }, // Shifted by 40% for mobile
              }}
            >
              <LinkedInIcon
                sx={{ fontSize: { xs: 25, sm: 30 }, color: "black" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </AppBar>

      {/* Centered Icons for Address, Phone, and Email below the navbar */}
      <Grid
        container
        justifyContent="center"
        spacing={2} // Reduced spacing for mobile
        sx={{ marginTop: { xs: "10px", sm: "-30px" } }} // Adjust margin for mobile
      >
        {/* Location Icon and Address */}
        <Grid item>
          <IconButton
            onClick={handleLocationClick}
            sx={{
              padding: { xs: "3px", sm: "5px" }, // Smaller padding for mobile
              backgroundColor: "orange",
              borderRadius: "4px",
              top: { xs: "20%", sm: "0%" }, // Shifted down by 20%
            }}
          >
            <LocationOnIcon
              sx={{ fontSize: { xs: 25, sm: 30 }, color: "black" }}
            />
          </IconButton>
          <span
            style={{
              marginLeft: "8px",
              color: "black",
              fontSize: "14px",
              position: "relative",
              top: "20%", // Text also shifted down by 20%
            }}
          >
            Address: Tejomaya Bharata
          </span>
        </Grid>

        {/* Phone Icon and Number */}
        <Grid item>
          <IconButton
            onClick={handlePhoneClick}
            sx={{
              padding: { xs: "3px", sm: "5px" },
              backgroundColor: "orange",
              borderRadius: "4px",
              top: { xs: "20%", sm: "0%" }, // Shifted down by 20%
            }}
          >
            <PhoneIcon sx={{ fontSize: { xs: 25, sm: 30 }, color: "black" }} />
          </IconButton>
          <span
            style={{
              marginLeft: "8px",
              color: "black",
              fontSize: "14px",
              position: "relative",
              top: "20%", // Text also shifted down by 20%
            }}
          >
            Phone: +91-9591075999
          </span>
        </Grid>

        {/* Email Icon and Address */}
        <Grid item>
          <IconButton
            onClick={handleEmailClick}
            sx={{
              padding: { xs: "3px", sm: "5px" },
              backgroundColor: "orange",
              borderRadius: "4px",
              top: { xs: "20%", sm: "0%" }, // Shifted down by 20%
            }}
          >
            <EmailIcon sx={{ fontSize: { xs: 25, sm: 30 }, color: "black" }} />
          </IconButton>
          <span
            style={{
              marginLeft: "8px",
              color: "black",
              fontSize: "14px",
              position: "relative",
              top: "20%", // Text also shifted down by 20%
            }}
          >
            Email: tejomayabharatha@gmail.com
          </span>
        </Grid>
      </Grid>

      {/* Logo Section */}
      <Grid
        container
        justifyContent="flex-start"
        sx={{ marginTop: { xs: "10px", sm: "-10px" }, marginLeft: "20px" }} // Adjust for mobile
      >
        <Grid item>
          <img
            src="/English_Logo.jpg"
            alt="Logo"
            style={{ width: "140px", height: "auto" }} // Smaller logo for mobile
          />
        </Grid>
      </Grid>

      {/* Buttons Section */}
      <Grid
        container
        justifyContent="center"
        spacing={2}
        sx={{ marginTop: { xs: "10px", sm: "-30px" } }} // Adjust margin for mobile
      >
        {[
          
          "Delete Image"
        ].map((text) => (
          <Grid item key={text}>
            <NavLink
              to={`/${text.replace(" ", "").toLowerCase()}`}
              style={({ isActive }) => ({
                color: isActive ? "white" : "black",
                backgroundColor: isActive ? "#D2B48C" : "transparent",
                borderRadius: isActive ? "190px" : "0px",
              })}
            >
              <Button
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: { xs: "18px", sm: "23px" }, // Smaller font for mobile
                  "&:hover": {
                    backgroundColor: "#D2B48C", // Brown on hover
                  },
                }}
              >
                {text}
              </Button>
            </NavLink>
          </Grid>
        ))}
      </Grid>

      {/* Line Below the Buttons */}
      <Grid
        container
        justifyContent="center"
        sx={{ marginTop: "10px", width: "100%" }}
      >
        <Grid item xs={12}>
          {" "}
          {/* Ensure it takes full width */}
          <div
            style={{
              height: "2px",
              backgroundColor: "#FFA500",
              width: "100%",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;
