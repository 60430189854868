// src/Gallery/ImageGallery.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // Import useParams for route parameters
import axios from "axios";
import CategoryList from "./CategoryList"; // Import the CategoryList component

const ImageGallery = () => {
  const { categoryName } = useParams(); // Get the category name from URL parameters
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch images when the component mounts or when the category changes
  useEffect(() => {
    const fetchImagesByCategory = async () => {
      setLoading(true);
      setError(null); // Reset error state

      try {
        const response = await axios.get(
          `https://gallery-ln9c.onrender.com/api/images/category_id/${categoryName}`
        ); // Fetch images based on the selected category
        console.log(response.data); // Log the response data for debugging

        // Assuming the API returns an array of ImageResponseDto
        setImages(response.data); // Set images received from the API
      } catch (err) {
        setError(err.message); // Set error if fetching fails
      } finally {
        setLoading(false); // Reset loading state
      }
    };

    fetchImagesByCategory(); // Call the fetch function
  }, [categoryName]); // Dependency array: fetch images when categoryName changes

  const handleDelete = async (imageId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this image?"); // Confirmation popup

    if (confirmDelete) { // Proceed only if the user clicked "OK"
      try {
        await axios.delete(`https://gallery-ln9c.onrender.com/api/images/delete/${imageId}`); // Call delete API
        setImages((prevImages) => prevImages.filter((image) => image.id !== imageId)); // Remove deleted image from state
      } catch (err) {
        console.error("Error deleting image:", err); // Log error if delete fails
      }
    }
  };

  return (
    <div className="gallery">
      <CategoryList onCategorySelect={(category) => {}} /> {/* Pass an empty function if no action is needed */}
      {loading && <p>Loading images...</p>}
      {error && <p>Error fetching images: {error}</p>}
      {images.length === 0 && !loading && <p>No images available in this category.</p>}
      {images.length > 0 && (
        <>
          <h3>Images in Category: {categoryName}</h3>
          <div className="image-grid">
            {images.map((image) => (
              <div key={image.id} className="image-card">
                <h4>Image ID: {image.id}</h4>
                <img
                  src={`data:image/jpeg;base64,${image.base64Image}`} // Use image.base64Image for base64 string
                  alt={`Image ID: ${image.id}`}
                  style={{ width: "200px", height: "auto" }}
                />
                <button onClick={() => handleDelete(image.id)}>Delete</button> {/* Delete button */}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ImageGallery;
