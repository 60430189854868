// Layout.js
import React from "react";
import { CssBaseline, Box } from "@mui/material";
import Header from "../Layout/Header"; // Your Header component
import Footer from "../Layout/Footer"; // Your Footer component

const Layout = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Ensures the container takes full viewport height
      }}
    >
      <CssBaseline /> {/* Ensures consistent baseline CSS */}
      <Header /> {/* Removed viewport prop */}
      <Box
        component="main"
        sx={{
          flex: 1, // Takes up remaining space
          padding: "20px",
        }}
      >
        {children}
      </Box>
      <Footer /> {/* Removed viewport prop */}
    </Box>
  );
};

export default Layout;
