import React from "react";
import {
  Typography,
  Link as RouterLink,
  Container,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Footer = () => {
  const styles = {
    fontSize: { xs: "12px", sm: "14px" }, // Smaller font size for mobile
    footerHeight: "250px", // Set height to 250px
    backgroundColor: "#000", // Black background
    color: "#fff", // White text color
  };

  return (
    <Grid
      container
      component="footer"
      sx={{
        backgroundColor: styles.backgroundColor,
        color: styles.color,
        padding: { xs: "20px", sm: "40px" }, // Smaller padding for mobile
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={4} // Adds consistent spacing between all grid items
          sx={{
            justifyContent: { xs: "center", sm: "space-between" }, // Center content on mobile, space out on larger screens
            textAlign: { xs: "center", sm: "left" }, // Center text on mobile, align left on larger screens
          }}
        >
          {/* About Us */}
          <Grid
            item
            xs={12}
            sm={6}
            md={3} // Each column takes equal space on medium screens and larger
          >
            <Typography variant="h6" sx={{ color: "#FF8C00" }}>
              About Us
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#fff",
                fontSize: styles.fontSize,
                maxWidth: { xs: "100%", sm: "200px" }, // Full width on mobile, constrained on larger screens
                marginBottom: { xs: "20px", sm: "0px" }, // Add space below on mobile
              }}
            >
              At Tejomaya Bharata, we passionately promote cultural heritage,
              social awareness, and education, empowering individuals while
              fostering community engagement to ensure our values resonate with
              future generations.
            </Typography>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" sx={{ color: "#FF8C00" }}>
              Quick Links
            </Typography>
            <Grid container direction="column" spacing={1}>
              {["Home", "About Us", "Our Team", "Gallery", "Testimonials"].map(
                (text, index) => (
                  <Grid item key={index}>
                    <Link
                      component={RouterLink}
                      to={`/${text.replace(" ", "-").toLowerCase()}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography sx={{ color: "white", fontSize: styles.fontSize }}>
                        {text}
                      </Typography>
                    </Link>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>

          {/* Newsletter Signup */}
          <Grid item xs={12} sm={6} md={5}>
            <Typography
              variant="h6"
              sx={{
                color: "#FF8C00",
                marginBottom: "10px",
                textAlign: { xs: "center", sm: "left" }, // Center on mobile, left on larger screens
              }}
            >
              Newsletter Signup
            </Typography>
            <Grid
              container
              alignItems="center"
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "flex-start" }, // Center content on mobile
                marginBottom: { xs: "20px", sm: "0px" }, // Add space below on mobile
              }}
            >
              <TextField
                variant="outlined"
                size="small"
                placeholder="Enter your email"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  width: { xs: "70%", sm: "auto" }, // Full width on mobile, auto on larger screens
                }}
              />
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FF8C00",
                  color: "#fff",
                  height: "40px", // Match button height to the TextField
                  marginLeft: { xs: "10px", sm: "10px" }, // Add margin on mobile
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>

          {/* Contact Us */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" sx={{ color: "#FF8C00" }}>
              Contact Us
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#fff", fontSize: styles.fontSize }}
            >
              Email: tejomayabharatha@gmail.com
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#fff",
                fontSize: styles.fontSize,
                marginTop: "10px",
              }}
            >
              Phone: +91-9591075999
            </Typography>
          </Grid>
        </Grid>

        {/* Footer Bottom */}
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{
              textAlign: "center",
              color: "#fff",
              paddingTop: "10px",
              fontSize: styles.fontSize,
              marginTop: { xs: "20px", sm: "0px" }, // Add margin top on mobile for spacing
            }}
          >
            &copy; {new Date().getFullYear()} Tejomaya Bharatha. All rights
            reserved.
          </Typography>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Footer;
