// src/CategoryList.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Get navigate function

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://gallery-ln9c.onrender.com/api/images/category"
        ); // Adjust URL as needed
        setCategories(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const onCategorySelect = (category) => {
    navigate(`/deleteImage/${category}`); // Navigate to the delete image route with the category name
  };

  if (loading) {
    return <p>Loading categories...</p>;
  }

  if (error) {
    return <p>Error fetching categories: {error}</p>;
  }

  return (
    <div className="category-list">
      <h2>Select a Category</h2>
      <ul>
        {categories.map((category) => (
          <li key={category}>
            <button onClick={() => onCategorySelect(category)}>
              {category}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryList;
